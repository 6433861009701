<template>
	<header v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<div class="hero-slider">
				<ul class="slides">
					<li>
						<picture>
							<source
								v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
								:srcset="headingImage.imagePortraitWebp"
								media="(max-width: 500px)"
								type="image/webp"
							/>
							<source
								v-if="headingImage.imagePortrait"
								:srcset="headingImage.imagePortrait"
								media="(max-width: 500px)"
							/>
							<source :srcset="headingImage.imageWebp" type="image/webp" />
							<source :srcset="headingImage.image" />
							<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
						</picture>
						<slot name="heroContent" />
					</li>
				</ul>
			</div>
			<a v-if="route.name.startsWith('index')" class="scroll-down" @click="scrollDown()">
				<span>{{ $t('exploreOurHotel') }}</span>
				<div class="icon-bike">
					<font-awesome-icon icon="fa-light fa-person-biking" size="1x" />
				</div>
				<font-awesome-icon icon="fa-light fa-arrow-down" size="1x" />
			</a>
		</div>
	</header>
</template>

<script setup>
defineProps({
	headingImage: { type: Object, default: () => {} },
});

const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};
</script>

<style lang="scss" scoped>
.hero-image,
.hero-slider .slides {
	width: 100%;
	height: 100vh;
}

.subpage .hero-image,
.subpage .hero-slider .slides {
	height: 60vh;
}

.hero-image {
	overflow: hidden;

	.slides li {
		width: 100%;
		position: relative;
		height: 100%;

		&::after {
			content: '';
			display: block;
			z-index: 3;
			position: absolute;
			inset: 0;
			background: rgb(0 0 0 / 35%);
		}

		.row {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			left: 0;
			right: 0;
			z-index: 4;
		}

		.slider-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
			z-index: 2;
			transition: all 0.3s ease-in-out;
			animation:
				zoom-in 9000ms ease-in-out forwards,
				fade-in 800ms ease-out;
		}
	}

	.scroll-down {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		z-index: 4;
		font-weight: 300;
		cursor: pointer;

		svg {
			color: #fff;
			font-size: 20px;
			margin: 8px 0;
			transition: all 0.3s ease-in-out;
		}

		.icon-bike {
			svg {
				font-size: 30px;
				transition: all 0.3s ease-in-out;
			}
		}

		span {
			display: block;
			width: 100%;
			color: #fff;
			margin: 0 0 5px;
		}

		img {
			width: 60px;
			height: 60px;
			transition: all 0.3s ease-in-out;
		}

		&:hover {
			.icon-bike svg {
				transform: scale(1.1);
			}
		}
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.08);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 800px) {
	.hero-image .hero-content h2 {
		font-size: 3.4em;
	}

	.hero-image .slides li .slider-image {
		margin-top: -3vh;
		height: 103vh;
	}
}

@media (max-width: 600px) {
	.hero-image {
		.columns {
			padding: 0;
		}

		.hero-content {
			.content {
				h2 {
					font-size: 2.4em;
				}
			}
		}

		.scroll-down {
			bottom: 80px;

			span {
				display: none;
			}
		}
	}
}

@media (max-width: 500px) {
	.hero-image .hero-content {
		h2 {
			font-size: 34px;
		}

		h3 {
			font-size: 16px;
		}
	}
}
</style>
